const ContactData = [
    {
        id:0,
        link:'http://www.linkedin.com/in/sora-noh',
        icon:'fa-brands fa-linkedin'
    },
    {
        id:1,
        link:'mailto:nosora0422@gmail.com',
        icon:'fa-solid fa-envelope'
    },
    {
        id:2,
        link:'http://www.linkedin.com/in/sora-noh',
        icon:'fa-brands fa-github'
    },

]

export default ContactData;